import { resolve } from "path";

const API_URL = `${window.location.origin}/api/v1/servisor`;
// const API_URL = "http://localhost:5000/api/v1/servisor";
// console.log(API_URL)

// const headers = {
//   "Content-Type": "application/json;charset=UTF-8",
//   "Access-Control-Allow-Origin": "*",
//   Authorization: `Bearer ${window.localStorage.getItem("Authorization")}`,
// };


const getHeaders = () => ({

    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${window.localStorage.getItem("Authorization")}`,

})

export const LIST = async (url: string) => {
  try {
    const response = await fetch(`${API_URL}/${url}`, {
      method: "GET",
      headers: getHeaders(),
    });
 
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(await response.json());
    }
  } catch (error) {
    throw error;
  }
};

export const POST = async (url: string, params: any) => {
  try {
    const response = await fetch(`${API_URL}/${url}`, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(params),
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(await response.json());
    }
  } catch (error) {
    throw error;
  }
};

export const GET = async (url: string, params: any) => {
  try {
    const response = await fetch(`${API_URL}/${url}`, {
      method: "GET",
      headers: getHeaders(),
      // body: JSON.stringify(params)
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(await response.json());
    }
  } catch (error) {
    throw error;
  }
};

export const PUT = async (url: string, params: any) => {
  try {
    const response = await fetch(`${API_URL}/${url}`, {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify(params),
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(await response.json());
    }
  } catch (error) {
    throw error;
  }
};

export const DELETE = async (url: string, params: any) => {
  try {
    const response = await fetch(`${API_URL}/${url}/${params._id.$oid}`, {
      method: "DELETE",
      headers: getHeaders(),
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(await response.json());
    }
  } catch (error) {
    throw error;
  }
};


export const CUSTOMPUT = async (url: string, params: any) => {
  try {
      const response = await fetch(`${API_URL}/${url}`, {
          method: 'PUT',
          headers: getHeaders(),
          body: JSON.stringify(params)
      });
      const data = await response.json();
      if (response.ok) {
          return data;
      } else {
          throw data;
      }
  } catch (error) {
      throw error;
  }
}