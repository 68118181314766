import {
  IonContent,
  IonHeader,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Header from "../../components/header/Header";
import StartModal from "../../components/modals/StartModal";
import "./Register.scss";
// import PasswordStrengthBar from "react-password-strength-bar";
import SmileyFace from "../../assets/img/smiley.svg";
import LandingImage from "../../assets/img/Workshop Left_.png";
import Visible from "../../assets/img/icon-input-visibility-on.svg";
import InVisible from "../../assets/img/icon-input-visibility-off.svg";

import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { checkEmail, signup } from "../../services/auth.service";
import { RegisterModel } from "../../models/RegisterModel";
import { RMI } from "../../models/RmiModel";

const Register: React.FC = () => {
  const history = useHistory();
  const [errorMsg, setErrorMsg] = useState("");
  const [showpassword, setShowpassword] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [statusError, setStatusError] = useState(false);

  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");

  const [nameError, setNameError] = useState(false);
  const [nameErrorMsg, setNameErrorMsg] = useState("")

  const [surnameError, setSurnameError] = useState(false);
  const [surnameErrorMsg, setSurnameErrorMsg] = useState("")

  const [emailerror, setEmailError] = useState(false);
  const [registerDetails, setRegister] = useState(
    new RegisterModel({
      name: "",
      surname: "",
      phone_number: "",
      email: "",
      password: "",
      confirmpassword: "",
      rmi: "",
    })
  );

  const [passwordError, setPasswordError] = useState("");
  // const passwordPattern = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]).{4,}$/;
  const passwordPattern = /^(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*\?]).{6,}$/;

  const handlePasswordChange = (event: any) => {
    const password = event.target.value;
    setRegister({
      ...registerDetails,
      password: password,
    });

    if (!passwordPattern.test(password)) {
      setPasswordError(" Password must contain at least 6 characters, including at least one numeric digit, one special character, and one uppercase letter.");
    } else {
      setPasswordError("");
    }
  };

  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const handleConfirmPasswordChange = (event: any) => {
    const confirmPassword = event.target.value;
    setRegister({
      ...registerDetails,
      confirmpassword: confirmPassword,
    });

    if (confirmPassword !== registerDetails.password) {
      setConfirmPasswordError("Passwords don't match.");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handlePhoneNumberChange = (event: any) => {
    const phoneNumber = event.target.value;
    setRegister({
      ...registerDetails,
      phone_number: phoneNumber,
    });

    const phonePattern = /^(?:\+27|0)[0-9]{9}$/;
    if (!phonePattern.test(phoneNumber)) {
      setPhoneError("Please enter a valid phone number");
    } else {
      setPhoneError("");
    }
  };

  const handleNameChange = (event: any) => {
    const name = event.target.value;
    setRegister({
      ...registerDetails,
      name: name,
    });

    const namePattern = /^[A-Za-z]+$/;
    if (!namePattern.test(name)) {
      setNameError(true);
      setNameErrorMsg("Name can not include a number");
    } else {
      setNameErrorMsg("");
    }
  };

  const handleSurnameChange = (event: any) => {
    const surname = event.target.value;
    setRegister({
      ...registerDetails,
      surname: surname,
    });

    const surnamePattern = /^[A-Za-z]+$/;
    if (!surnamePattern.test(surname)) {
      setSurnameError(true);
      setSurnameErrorMsg("Surname can not include a number");
    } else {
      setSurnameErrorMsg("");
    }
  };


  const emailpattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$";
  const origin = `${window.location.origin}/signin`;

  const submitRegister = () => {
    setClicked(true);
    setStatusError(false);
    let userDetails: any = Object.assign({}, registerDetails);
    delete userDetails.confirmpassword;

    checkEmail({ email: registerDetails.email })
      .then((signupResponse: any) => {
        if (signupResponse.status === true) {
          setErrorMsg("Already registered user, Please Log In");
          setClicked(false);
          return;
        }

        console.log(signupResponse, "signupResponse");
        window.localStorage.setItem("Email", registerDetails.email);
        window.localStorage.setItem(
          "userDetails",
          JSON.stringify(registerDetails)
        );
        window.localStorage.setItem(
          "Authorization",
          signupResponse.access_token
        );
        history.push("/onboarding/1");
        // history.push("/onboarded")
        setClicked(false);
      })
      .catch((error) => {
        if (JSON.stringify(error) === "{}") {
          if (error.status === true) {
            setErrorMsg("Already registered user, Please Log In");
          } else {
            setErrorMsg("Something went wrong, please try again later.");
            console.log(JSON.stringify(error));
          }

          setClicked(false);
        }
      });
  };

  return (
    <>
      <IonPage>
        <IonContent fullscreen>
          <Header />
          <div className="container">
            <div className="row mt-5">
              <div className="col-xs-12 col-sm-12 col-md-5 col-lg-7">
                <h3 className="fw-light headline-text">
                  Welcome! You’re steps away from joining the Servisor Network.
                </h3>
                <img className="left-image" src={LandingImage} />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5  right-scrollable">
                <div className="card">
                  <div className="card-body p-0">
                    <h6 className="card-title fw-bolder mb-3">
                      Register an account
                    </h6>
                    <p className="text-left">
                      Already Registered? &nbsp;
                      <button
                        type="button"
                        className="btn btn-link p-0"
                        onClick={() => history.push("/signin")}
                      >
                        Log in here
                      </button>
                    </p>
 
                    <form>
                      <div className="form-group">
                        <label className="form-label fw-bolder">Name</label>
                        <input
                          className="form-control"
                          value={registerDetails.name}
                          onChange={(ev: any) => {
                            handleNameChange(ev);
                            registerDetails.name = ev.target.value;
                            setRegister(new RegisterModel(registerDetails));
                          }}
                          type="text"
                          name="firstname"
                        />
                      </div>
                      {nameError && (
                        <p className="text-danger">
                          <small>{nameErrorMsg}</small>
                        </p>
                      )}
                      <div className="form-group">
                        <label className="form-label fw-bolder">Surname</label>
                        <input
                          className="form-control"
                          value={registerDetails.surname}
                          onChange={(ev: any) => {
                            handleSurnameChange(ev);
                            registerDetails.surname = ev.target.value;
                            setRegister(new RegisterModel(registerDetails));
                          }}
                          type="text"
                          name="lastname"
                        />
                      </div>
                      {surnameError && (
                        <p className="text-danger">
                          <small>{surnameErrorMsg}</small>
                        </p>
                      )}
                      
                      <div className="form-group">
                        <label className="form-label fw-bolder">
                          Email Address
                        </label>
                        <input
                          className="form-control"
                          value={registerDetails.email}
                          onChange={(ev: any) => {
                            const found = ev.target.value.match(emailpattern);
                            registerDetails.email = ev.target.value;
                            setRegister(new RegisterModel(registerDetails));
                            if (found) {
                              setEmailError(false);
                            } else {
                              setEmailError(true);
                            }
                          }}
                          type="email"
                          name="email"
                        />
                      </div>
                      {emailerror ? (
                        <p className="text-danger">
                          <small>Please enter a valid email.</small>
                        </p>
                      ) : null}

                      <div className="form-group">
                        <label className="form-label fw-bolder">
                          Phone Number
                        </label>
                        <input
                          className="form-control"
                          value={registerDetails.phone_number}
                          onChange={(ev: any) => {
                            handlePhoneNumberChange(ev);
                            registerDetails.phone_number = ev.target.value;
                            setRegister(new RegisterModel(registerDetails));
                          }}
                          type="text"
                          name="phone"
                        />
                      </div>
                      {phoneError && (
                        <p className="text-danger">
                          <small>{phoneError}</small>
                        </p>
                      )}

                      <div className="form-group">
                        <label className="form-label fw-bolder">
                          Create Password
                        </label>
                        {!showpassword ? (
                          <div className="password-container">
                            <input
                              className="form-control"
                              value={registerDetails.password}
                              onChange={handlePasswordChange}
                              type="password"
                              name="password"
                            />
                            <img
                              src={InVisible}
                              className="toggle-password"
                              onClick={() => {
                                setShowpassword(!showpassword);
                              }}
                            />
                          </div>
                        ) : (
                          <div className="password-container">
                            <input
                              className="form-control"
                              value={registerDetails.password}
                              onChange={handlePasswordChange}
                              type="text"
                              name="password"
                            />
                            <img
                              src={Visible}
                              className="toggle-password"
                              onClick={() => {
                                setShowpassword(!showpassword);
                              }}
                            />
                          </div>
                        )}
                        {/* {registerDetails.password !==
                        registerDetails.confirmpassword ? (
                          <p className="text-danger">
                            <small>Passwords don't match.</small>
                          </p>
                        ) : null} */}

                        {passwordError && (
                          <p className="text-danger">
                            <small>
                              {passwordError}
                            </small>
                          </p>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="form-label fw-bolder">
                          Re-Enter&nbsp;Password
                        </label>
                        {!showpassword ? (
                          <div className="password-container">
                            <input
                              className="form-control"
                              value={registerDetails.confirmpassword}
                              onChange={handleConfirmPasswordChange}
                              type="password"
                              name="confirm_password"
                            />
                            <img
                              src={InVisible}
                              className="toggle-password"
                              onClick={() => {
                                setShowpassword(!showpassword);
                              }}
                            />
                          </div>
                        ) : (
                          <div className="password-container">
                            <input
                              className="form-control"
                              value={registerDetails.confirmpassword}
                              onChange={handleConfirmPasswordChange}
                              type="text"
                              name="confirm_password"
                            />
                            <img
                              src={Visible}
                              className="toggle-password"
                              onClick={() => {
                                setShowpassword(!showpassword);
                              }}
                            />
                          </div>
                        )}
                        {/* {registerDetails.password !==
                        registerDetails.confirmpassword ? (
                          <p className="text-danger">
                            <small>Passwords don't match.</small>
                          </p>
                        ) : null} */}

                        {confirmPasswordError && (
                          <p className="text-danger">
                            <small>{confirmPasswordError}</small>
                          </p>
                        )}
                      </div>
                      {/* <PasswordStrengthBar
                        password={registerDetails.password}
                      /> */}
                    </form>
                    
                    <p className="text-left text-danger">{errorMsg}</p>
                    <div className="spacer"></div>
                    <div className="row">
                      {statusError ? (
                        <p className="text-danger">
                          <small>
                            User Already Registered ,{" "}
                            <a href={origin}> Please Log in.</a>
                          </small>
                        </p>
                      ) : null}
                      <div className="col">
                        <button
                          className="btn btn-warning btn-lg w-100"
                          id="alert-modal"
                          type="button"
                          disabled={
                            !registerDetails.name ||
                            !registerDetails.surname ||
                            !registerDetails.email ||
                            !registerDetails.phone_number ||
                            emailerror ||
                            !!phoneError ||
                            !registerDetails.password ||
                            !registerDetails.confirmpassword ||
!!passwordError || 
!!confirmPasswordError
                          }
                          onClick={() => {
                            submitRegister();
                          }}
                        >
                          Next
                          {clicked ? (
                            <IonSpinner name="crescent"></IonSpinner>
                          ) : null}
                        </button>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    {/* <p className="text-left">
                      Already Registered? &nbsp;
                      <a href={origin} target="_blank">
                        Log in Here
                      </a>
                    </p> */}
                    <br />
                    <p className="text-left">
                      <small>
                        By clicking 'Next' you agree to the &nbsp;
                        <a
                          href="https://www.servisor.co.za/legal/terms-and-conditions"
                          target="_blank"
                        >
                          Ts &amp; Cs
                        </a>
                        &nbsp; and acknowledge and agree to abide by the Service
                        Level Agreement &nbsp;
                        <a
                          href="https://www.servisor.co.za/legal/service-level-agreement"
                          target="_blank"
                        >
                          (SLA).
                        </a>
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
      <StartModal />
    </>
  );
};

export default Register;
