export const WorkShopRmiData = [
    {
        "Workshop": "@ Auto Clinic",
        "RMI Number": 1005900
    },
    {
        "Workshop": "4 Wheel Traction Technologies",
        "RMI Number": 1004718
    },
    {
        "Workshop": "A & K Motors  -e-car",
        "RMI Number": 1004830
    },
    {
        "Workshop": "A & P Engine Centre",
        "RMI Number": 4004293
    },
    {
        "Workshop": "A And M Pump Services",
        "RMI Number": 1006469
    },
    {
        "Workshop": "A1 Car Service",
        "RMI Number": 4005854
    },
    {
        "Workshop": "Aa Auto Centre Kya Sands",
        "RMI Number": 1007413
    },
    {
        "Workshop": "Aaa Service Centre",
        "RMI Number": 4002778
    },
    {
        "Workshop": "Aai Auto Bosch Car Service Centre",
        "RMI Number": 1006571
    },
    {
        "Workshop": "Ab Motorsport",
        "RMI Number": 4004753
    },
    {
        "Workshop": "Ac Auto Services",
        "RMI Number": 1006856
    },
    {
        "Workshop": "Ad Automotive",
        "RMI Number": 1006259
    },
    {
        "Workshop": "Advanced Commercial Truck Repairs",
        "RMI Number": 1005521
    },
    {
        "Workshop": "Ae Workshop",
        "RMI Number": 1006049
    },
    {
        "Workshop": "Aero Motor Dynamics",
        "RMI Number": 4004517
    },
    {
        "Workshop": "After Market Commercial Solutions  Lt",
        "RMI Number": 1007560
    },
    {
        "Workshop": "Alan Black  ",
        "RMI Number": 1000053
    },
    {
        "Workshop": "All-o-matic Transmissions ",
        "RMI Number": 1000071
    },
    {
        "Workshop": "Amalgam Autobody",
        "RMI Number": 1006586
    },
    {
        "Workshop": "Amana Motors Cc",
        "RMI Number": 4000032
    },
    {
        "Workshop": "American Motorsport Racing Products",
        "RMI Number": 1000085
    },
    {
        "Workshop": "American Suv",
        "RMI Number": 4002466
    },
    {
        "Workshop": "Amo And Gops Motor Services And Tyre Rep",
        "RMI Number": 4006128
    },
    {
        "Workshop": "Amtec Service Centre  ",
        "RMI Number": 1000088
    },
    {
        "Workshop": "Andre Bam Auto Clinic",
        "RMI Number": 1003058
    },
    {
        "Workshop": "Asap Motors",
        "RMI Number": 1006311
    },
    {
        "Workshop": "Associated Automotive Distributors ",
        "RMI Number": 1000469
    },
    {
        "Workshop": "Atc Automotive Technical Centre",
        "RMI Number": 4005867
    },
    {
        "Workshop": "Auto Box Cc",
        "RMI Number": 4000099
    },
    {
        "Workshop": "Auto Care North Riding",
        "RMI Number": 1005244
    },
    {
        "Workshop": "Auto Care Workshop",
        "RMI Number": 1007223
    },
    {
        "Workshop": "Auto Cool",
        "RMI Number": 1000142
    },
    {
        "Workshop": "Auto Elegance",
        "RMI Number": 1002944
    },
    {
        "Workshop": "Auto Gp Motors",
        "RMI Number": 1006800
    },
    {
        "Workshop": "Auto I Workshop \u2013 Auto Investment",
        "RMI Number": 4003685
    },
    {
        "Workshop": "Auto Kimz",
        "RMI Number": 1007717
    },
    {
        "Workshop": "Auto Magic Mustang",
        "RMI Number": 1004125
    },
    {
        "Workshop": "Auto Mech",
        "RMI Number": 4003834
    },
    {
        "Workshop": "Auto Pedigree Service Centre Head Office",
        "RMI Number": 1007467
    },
    {
        "Workshop": "Auto Star Sa",
        "RMI Number": 1007614
    },
    {
        "Workshop": "Auto Superior",
        "RMI Number": 1000174
    },
    {
        "Workshop": "Auto Touch",
        "RMI Number": 1004675
    },
    {
        "Workshop": "Auto Worx",
        "RMI Number": 1002268
    },
    {
        "Workshop": "Autocrew \u2013 Blackheath Autotronic",
        "RMI Number": 1005745
    },
    {
        "Workshop": "Autoden",
        "RMI Number": 1007460
    },
    {
        "Workshop": "Automac",
        "RMI Number": 1005546
    },
    {
        "Workshop": "Auto-mate Service Centre Greenstone",
        "RMI Number": 1006014
    },
    {
        "Workshop": "Autoserv Solutions  ",
        "RMI Number": 4004479
    },
    {
        "Workshop": "Autotek Traxx",
        "RMI Number": 4005888
    },
    {
        "Workshop": "Autoway Service Centre \u2013 Ruimsig",
        "RMI Number": 1005596
    },
    {
        "Workshop": "Avenya",
        "RMI Number": 1007725
    },
    {
        "Workshop": "B & T Benz Chaps",
        "RMI Number": 1006852
    },
    {
        "Workshop": "B&r Auto Joburg",
        "RMI Number": 1007754
    },
    {
        "Workshop": "Banda Car Services",
        "RMI Number": 4004345
    },
    {
        "Workshop": "Barleda 77",
        "RMI Number": 4005299
    },
    {
        "Workshop": "Barrage Service Centre",
        "RMI Number": 1008000
    },
    {
        "Workshop": "Bavarian Workshop",
        "RMI Number": 1006367
    },
    {
        "Workshop": "Bb Silverton Nissan",
        "RMI Number": 4001746
    },
    {
        "Workshop": "Bb Vision Auto Repair",
        "RMI Number": 1007519
    },
    {
        "Workshop": "Bem Basic Electronics And Mechanical",
        "RMI Number": 4005459
    },
    {
        "Workshop": "Benzecutive Motors",
        "RMI Number": 1006632
    },
    {
        "Workshop": "Bestdrive Vereeniging",
        "RMI Number": 1005226
    },
    {
        "Workshop": "Blazing Tech  ",
        "RMI Number": 4004502
    },
    {
        "Workshop": "Bm Auto Orchards",
        "RMI Number": 4004488
    },
    {
        "Workshop": "Bm Auto Worx",
        "RMI Number": 1007429
    },
    {
        "Workshop": "B-ma Services",
        "RMI Number": 4003845
    },
    {
        "Workshop": "Bmaas",
        "RMI Number": 1007937
    },
    {
        "Workshop": "Bms Automotive",
        "RMI Number": 1007563
    },
    {
        "Workshop": "Bonyikho Auto Parts And Engineering Cc",
        "RMI Number": 4005419
    },
    {
        "Workshop": "Booysens Auto Bosch Car Service",
        "RMI Number": 1008059
    },
    {
        "Workshop": "Bosch Car Service",
        "RMI Number": 1007349
    },
    {
        "Workshop": "Bosch Car Service",
        "RMI Number": 4004149
    },
    {
        "Workshop": "Bosch Car Service Montana",
        "RMI Number": 4006144
    },
    {
        "Workshop": "Bosch Car Service Rosslyn",
        "RMI Number": 4006003
    },
    {
        "Workshop": "Bosch Express Mall Of The South",
        "RMI Number": 1008140
    },
    {
        "Workshop": "Bosch Express-rifle Range",
        "RMI Number": 1008175
    },
    {
        "Workshop": "Bosch Stone Ridge",
        "RMI Number": 1007470
    },
    {
        "Workshop": "Botha On Wekswinkel",
        "RMI Number": 1008178
    },
    {
        "Workshop": "Braamfontein Service Centre",
        "RMI Number": 1007704
    },
    {
        "Workshop": "British 4\u00d74",
        "RMI Number": 4002317
    },
    {
        "Workshop": "Britsweg",
        "RMI Number": 4000202
    },
    {
        "Workshop": "Browns Auto",
        "RMI Number": 1005532
    },
    {
        "Workshop": "Browns Auto World",
        "RMI Number": 1007906
    },
    {
        "Workshop": "Bsf Services And Gearbox Centre",
        "RMI Number": 1007822
    },
    {
        "Workshop": "Bt Repairs And Maintenance",
        "RMI Number": 1007664
    },
    {
        "Workshop": "Budler Motorsport",
        "RMI Number": 1004227
    },
    {
        "Workshop": "Bus And Truck Centre",
        "RMI Number": 4003723
    },
    {
        "Workshop": "C A R S",
        "RMI Number": 4004324
    },
    {
        "Workshop": "Caelex Chris Auto Electrical Parts Speci",
        "RMI Number": 4000221
    },
    {
        "Workshop": "Camaro Auto ( Ecar)",
        "RMI Number": 1008091
    },
    {
        "Workshop": "Cams Towing &repair Services",
        "RMI Number": 1006804
    },
    {
        "Workshop": "Canimambo Auto Spares & Repairs",
        "RMI Number": 1005660
    },
    {
        "Workshop": "Car & Truck Auto Electrical-e-car",
        "RMI Number": 1004871
    },
    {
        "Workshop": "Car And Truck City",
        "RMI Number": 1007637
    },
    {
        "Workshop": "Car Care Clinic \u2013 Menlyn",
        "RMI Number": 4004691
    },
    {
        "Workshop": "Car Care Clinic \u2013 Silverton",
        "RMI Number": 4004970
    },
    {
        "Workshop": "Car Care Clinic \u2013Brooklyn",
        "RMI Number": 4005006
    },
    {
        "Workshop": "Car Care Clinic \u2013Centurion",
        "RMI Number": 4004482
    },
    {
        "Workshop": "Car Care Clinic \u2013Craighall",
        "RMI Number": 1007963
    },
    {
        "Workshop": "Car Care Clinic \u2013Little Falls",
        "RMI Number": 1006070
    },
    {
        "Workshop": "Car Care Clinic \u2013Lynnwood ",
        "RMI Number": 4004465
    },
    {
        "Workshop": "Car Care Clinic \u2013Melrose",
        "RMI Number": 1007713
    },
    {
        "Workshop": "Car Care Clinic \u2013Midrand",
        "RMI Number": 1006370
    },
    {
        "Workshop": "Car Care Clinic \u2013Northcliff",
        "RMI Number": 1007789
    },
    {
        "Workshop": "Car Care Clinic \u2013Nothriding",
        "RMI Number": 1005903
    },
    {
        "Workshop": "Car Care Clinic \u2013Sunninghill",
        "RMI Number": 1008020
    },
    {
        "Workshop": "Car Care Clinic \u2013Valley View",
        "RMI Number": 1006156
    },
    {
        "Workshop": "Car Care Clinic \u2013Vanderbijlpark",
        "RMI Number": 1007919
    },
    {
        "Workshop": "Car Care Clinic \u2013Vereeniging",
        "RMI Number": 1007916
    },
    {
        "Workshop": "Car Care Clinic \u2013Wilrogate",
        "RMI Number": 1006527
    },
    {
        "Workshop": "Car Care Clinic \u2013Wonderboom",
        "RMI Number": 4006337
    },
    {
        "Workshop": "Car Rep",
        "RMI Number": 1005430
    },
    {
        "Workshop": "Car Security & Sound Centre",
        "RMI Number": 1000358
    },
    {
        "Workshop": "Car Service & Repair Centre",
        "RMI Number": 4004767
    },
    {
        "Workshop": "Car Service City \u2013 Alberton",
        "RMI Number": 1006326
    },
    {
        "Workshop": "Car Service City \u2013 Benoni Lakeside",
        "RMI Number": 1005792
    },
    {
        "Workshop": "Car Service City \u2013 Boksburg",
        "RMI Number": 1006024
    },
    {
        "Workshop": "Car Service City \u2013 Constantia",
        "RMI Number": 1006573
    },
    {
        "Workshop": "Car Service City \u2013 Hatfield",
        "RMI Number": 4003042
    },
    {
        "Workshop": "Car Service City \u2013 Horizon",
        "RMI Number": 1005178
    },
    {
        "Workshop": "Car Service City \u2013 Jackal Creek",
        "RMI Number": 1006159
    },
    {
        "Workshop": "Car Service City \u2013 Kempton Park",
        "RMI Number": 1006114
    },
    {
        "Workshop": "Car Service City \u2013 Kyalami",
        "RMI Number": 1005831
    },
    {
        "Workshop": "Car Service City \u2013 Linden",
        "RMI Number": 1005985
    },
    {
        "Workshop": "Car Service City \u2013 Linmeyer",
        "RMI Number": 1004669
    },
    {
        "Workshop": "Car Service City \u2013 Lynnwood  Lt",
        "RMI Number": 4003035
    },
    {
        "Workshop": "Car Service City \u2013 Midrand",
        "RMI Number": 1004509
    },
    {
        "Workshop": "Car Service City \u2013 Northriding",
        "RMI Number": 1007240
    },
    {
        "Workshop": "Car Service City \u2013 Ormonde",
        "RMI Number": 1005487
    },
    {
        "Workshop": "Car Service City \u2013 Randburg",
        "RMI Number": 1007242
    },
    {
        "Workshop": "Car Service City \u2013 Rivonia",
        "RMI Number": 1006654
    },
    {
        "Workshop": "Car Service City \u2013 Sunninghill",
        "RMI Number": 1005418
    },
    {
        "Workshop": "Car Service City \u2013 Vanderbijlpark",
        "RMI Number": 1006136
    },
    {
        "Workshop": "Car Service City \u2013 Vermeulen Stree",
        "RMI Number": 4002654
    },
    {
        "Workshop": "Car Service City \u2013 Wadeville",
        "RMI Number": 1006386
    },
    {
        "Workshop": "Car Service City \u2013 Westgate",
        "RMI Number": 1005179
    },
    {
        "Workshop": "Car Service City \u2013 Centurion Gautrain",
        "RMI Number": 4003353
    },
    {
        "Workshop": "Car Service City \u2013Bruma",
        "RMI Number": 1007267
    },
    {
        "Workshop": "Car Service City \u2013Cedar",
        "RMI Number": 1006968
    },
    {
        "Workshop": "Car Service City\u2013 Menlyn",
        "RMI Number": 4005104
    },
    {
        "Workshop": "Car Service City\u2013 Montana",
        "RMI Number": 4005310
    },
    {
        "Workshop": "Car Service City \u2013Sandton",
        "RMI Number": 1006689
    },
    {
        "Workshop": "Car Service City \u2013William Nicol",
        "RMI Number": 1005976
    },
    {
        "Workshop": "Car Service City \u2013Woodmead",
        "RMI Number": 1004877
    },
    {
        "Workshop": "Carlos W Power  ",
        "RMI Number": 4000239
    },
    {
        "Workshop": "Carmac Auto",
        "RMI Number": 1007422
    },
    {
        "Workshop": "Carpro Service Centre .",
        "RMI Number": 1003066
    },
    {
        "Workshop": "Cars Services Centre  ",
        "RMI Number": 1004037
    },
    {
        "Workshop": "Cartime \u2013 Benoni",
        "RMI Number": 1007685
    },
    {
        "Workshop": "Cartime \u2013 Centurion",
        "RMI Number": 4005682
    },
    {
        "Workshop": "Cartime \u2013 Hatfield",
        "RMI Number": 4005864
    },
    {
        "Workshop": "Cartime\u2013Sunninghill",
        "RMI Number": 1007698
    },
    {
        "Workshop": "CassiesMotors",
        "RMI Number": 4000246
    },
    {
        "Workshop": "Cats Autohaus Cc",
        "RMI Number": 1004542
    },
    {
        "Workshop": "Celtis Ridge \u2013 Car Service City",
        "RMI Number": 4005360
    },
    {
        "Workshop": "Cencar \/ Cendiesel",
        "RMI Number": 4000251
    },
    {
        "Workshop": "Centurion Auto Clinic",
        "RMI Number": 4005054
    },
    {
        "Workshop": "Centurion Diesel & Petrol Centre",
        "RMI Number": 4002777
    },
    {
        "Workshop": "Centurion Lake Auto",
        "RMI Number": 4002394
    },
    {
        "Workshop": "Charlies Diesel Centre",
        "RMI Number": 1007648
    },
    {
        "Workshop": "Cisco Service Repair Centre Csrc",
        "RMI Number": 1008126
    },
    {
        "Workshop": "Clarendon Auto",
        "RMI Number": 1000407
    },
    {
        "Workshop": "C-net",
        "RMI Number": 1003522
    },
    {
        "Workshop": "Colab Tuned",
        "RMI Number": 1006959
    },
    {
        "Workshop": "Colins Auto Repairs",
        "RMI Number": 1007444
    },
    {
        "Workshop": "Concept Tuning",
        "RMI Number": 1000438
    },
    {
        "Workshop": "Corkys Service & Repairs",
        "RMI Number": 1003475
    },
    {
        "Workshop": "Cross Country Vehicle Solutions",
        "RMI Number": 1003392
    },
    {
        "Workshop": "Cts Automotive Company",
        "RMI Number": 4005596
    },
    {
        "Workshop": "Cubed Motor Works",
        "RMI Number": 1007974
    },
    {
        "Workshop": "Cumax 183",
        "RMI Number": 4002820
    },
    {
        "Workshop": "Custom Servicing And Restoration",
        "RMI Number": 1007816
    },
    {
        "Workshop": "Cv Master Cc",
        "RMI Number": 4001481
    },
    {
        "Workshop": "D & F Diesel Fuel Services",
        "RMI Number": 4002481
    },
    {
        "Workshop": "D And J Workshop",
        "RMI Number": 4004630
    },
    {
        "Workshop": "D3l Automotive",
        "RMI Number": 1003944
    },
    {
        "Workshop": "Dail Automotive Service",
        "RMI Number": 1008099
    },
    {
        "Workshop": "Dark Artz Performance Cc",
        "RMI Number": 1005666
    },
    {
        "Workshop": "Das Auto Services",
        "RMI Number": 1004039
    },
    {
        "Workshop": "Dastek  ",
        "RMI Number": 4003653
    },
    {
        "Workshop": "Dcb Automotive",
        "RMI Number": 4006218
    },
    {
        "Workshop": "Dci Diesel & Performance",
        "RMI Number": 4006072
    },
    {
        "Workshop": "De Wet Automotive Performance",
        "RMI Number": 4005667
    },
    {
        "Workshop": "Delport Diff & Gearbox Specialists",
        "RMI Number": 1004533
    },
    {
        "Workshop": "Deutsch Auto Service Centre",
        "RMI Number": 1006179
    },
    {
        "Workshop": "De-vine Techniques",
        "RMI Number": 1007380
    },
    {
        "Workshop": "Diagtech Auto",
        "RMI Number": 1006836
    },
    {
        "Workshop": "Discount Wheel & Tyre",
        "RMI Number": 1005647
    },
    {
        "Workshop": "Draper Racing Development",
        "RMI Number": 1008177
    },
    {
        "Workshop": "Drive Plus Cresta",
        "RMI Number": 1007442
    },
    {
        "Workshop": "Drivetrain Service Centre",
        "RMI Number": 4002672
    },
    {
        "Workshop": "Dynamic Supply Chain Management Consulta",
        "RMI Number": 1007737
    },
    {
        "Workshop": "Dzunde Auto Solutions Cc",
        "RMI Number": 4002410
    },
    {
        "Workshop": "E & L Benz Auto",
        "RMI Number": 1007938
    },
    {
        "Workshop": "E & M Auto Services",
        "RMI Number": 4001528
    },
    {
        "Workshop": "E&g Motors",
        "RMI Number": 1005249
    },
    {
        "Workshop": "East Rand Truck Sales",
        "RMI Number": 1006127
    },
    {
        "Workshop": "East Sa Motor Works",
        "RMI Number": 1004329
    },
    {
        "Workshop": "E-car Menlyn Motor Services",
        "RMI Number": 4000843
    },
    {
        "Workshop": "Eco-tech Auto Solutions",
        "RMI Number": 4003666
    },
    {
        "Workshop": "Edenvale Auto Services",
        "RMI Number": 1003041
    },
    {
        "Workshop": "Edl Auto Service Centre",
        "RMI Number": 1007907
    },
    {
        "Workshop": "Efficient Car Service Centre",
        "RMI Number": 1007126
    },
    {
        "Workshop": "El Roi Lg Auto Electrical And Mechanical",
        "RMI Number": 1007806
    },
    {
        "Workshop": "Elegant Auto Group \u2013 Vereeniging",
        "RMI Number": 1007178
    },
    {
        "Workshop": "Elite Automotive Solutions",
        "RMI Number": 1007558
    },
    {
        "Workshop": "Eqstra Flexi Fleet Germiston Mechanical",
        "RMI Number": 1007258
    },
    {
        "Workshop": "Eqstra Flexi Fleet Workshop \u2013 Isan",
        "RMI Number": 1006061
    },
    {
        "Workshop": "Evolution Fleet Technologies",
        "RMI Number": 1007787
    },
    {
        "Workshop": "Executive Transmission  ",
        "RMI Number": 1005165
    },
    {
        "Workshop": "Extreme Performance",
        "RMI Number": 1007939
    },
    {
        "Workshop": "Fairland Auto Technical",
        "RMI Number": 1007475
    },
    {
        "Workshop": "Farias Auto Services",
        "RMI Number": 1007901
    },
    {
        "Workshop": "Farrell Auto Services",
        "RMI Number": 1007735
    },
    {
        "Workshop": "Finla Mechanic & Auto Electrical",
        "RMI Number": 4002847
    },
    {
        "Workshop": "Fit-it \u2013 Waterkloof Glen",
        "RMI Number": 4002868
    },
    {
        "Workshop": "Flexxx Motors",
        "RMI Number": 1007927
    },
    {
        "Workshop": "Flora Auto Clinic",
        "RMI Number": 1007252
    },
    {
        "Workshop": "Frik Nel Motors",
        "RMI Number": 4003352
    },
    {
        "Workshop": "Fst Auto Service Cc",
        "RMI Number": 1005185
    },
    {
        "Workshop": "G & C Performance Centre",
        "RMI Number": 1005703
    },
    {
        "Workshop": "G F I Motor Corporation",
        "RMI Number": 1003453
    },
    {
        "Workshop": "Gabe Mobile Auto",
        "RMI Number": 1007401
    },
    {
        "Workshop": "Garsfontein Service Workshop Cc",
        "RMI Number": 4002947
    },
    {
        "Workshop": "Gbs Autotroniks",
        "RMI Number": 1007538
    },
    {
        "Workshop": "Gearbox & Diff Rebuilding Cc",
        "RMI Number": 4000470
    },
    {
        "Workshop": "Gearbox And Differential Doctor",
        "RMI Number": 1006608
    },
    {
        "Workshop": "Gearbox Centre Cc",
        "RMI Number": 4000471
    },
    {
        "Workshop": "Gearbox Specialist",
        "RMI Number": 1007848
    },
    {
        "Workshop": "Gem Auto Service And Repairs",
        "RMI Number": 1007959
    },
    {
        "Workshop": "German Auto Perfection",
        "RMI Number": 1005552
    },
    {
        "Workshop": "German House Service",
        "RMI Number": 4004938
    },
    {
        "Workshop": "Gkt Motors",
        "RMI Number": 4003764
    },
    {
        "Workshop": "Glenridge Motors",
        "RMI Number": 4002337
    },
    {
        "Workshop": "Gmp Auto & Electrical",
        "RMI Number": 1003411
    },
    {
        "Workshop": "Go Power & Developments \u2013 Bos",
        "RMI Number": 1006153
    },
    {
        "Workshop": "Gorilla Pit Garage",
        "RMI Number": 1007203
    },
    {
        "Workshop": "Grace Of God Projects And Maintenance",
        "RMI Number": 1007303
    },
    {
        "Workshop": "Gst Sa Logistics",
        "RMI Number": 1007162
    },
    {
        "Workshop": "Gt One",
        "RMI Number": 1005638
    },
    {
        "Workshop": "G-tech Auto",
        "RMI Number": 1004634
    },
    {
        "Workshop": "Haider Gearbox Repairs",
        "RMI Number": 1007112
    },
    {
        "Workshop": "Hands On Car Services And Repairs",
        "RMI Number": 4005696
    },
    {
        "Workshop": "Hard Brake Service Centre",
        "RMI Number": 1008064
    },
    {
        "Workshop": "Harrys Auto Services",
        "RMI Number": 4000531
    },
    {
        "Workshop": "Hatfield Car Cafe",
        "RMI Number": 4005311
    },
    {
        "Workshop": "Hattingh Motors Cc",
        "RMI Number": 4000595
    },
    {
        "Workshop": "Hatwagen Motors",
        "RMI Number": 4002243
    },
    {
        "Workshop": "Henris Tune Up Centre",
        "RMI Number": 1004155
    },
    {
        "Workshop": "High Power Spares",
        "RMI Number": 4005613
    },
    {
        "Workshop": "Highpoint Service Centre And Panel Beate",
        "RMI Number": 1007791
    },
    {
        "Workshop": "Highveld Autoworks",
        "RMI Number": 4006185
    },
    {
        "Workshop": "Hino Pomona",
        "RMI Number": 1008007
    },
    {
        "Workshop": "Ilan Motors",
        "RMI Number": 1006323
    },
    {
        "Workshop": "Independent 4\u00d74  ",
        "RMI Number": 4002727
    },
    {
        "Workshop": "Influx Auto",
        "RMI Number": 4005876
    },
    {
        "Workshop": "Ingrid Autoz",
        "RMI Number": 1008067
    },
    {
        "Workshop": "Insyte Trading",
        "RMI Number": 1007794
    },
    {
        "Workshop": "Ithemba Automotive Services",
        "RMI Number": 1007918
    },
    {
        "Workshop": "Ivn Dienste",
        "RMI Number": 4003026
    },
    {
        "Workshop": "J Tech",
        "RMI Number": 4002415
    },
    {
        "Workshop": "Jds Auto",
        "RMI Number": 1005568
    },
    {
        "Workshop": "Jib Propshaft & Cv Joint",
        "RMI Number": 1005537
    },
    {
        "Workshop": "Jjs Automotive Service And Repair Centre",
        "RMI Number": 1008160
    },
    {
        "Workshop": "Jlj Auto Electrical Services And Spares",
        "RMI Number": 4000076
    },
    {
        "Workshop": "Jmm Auto Repairs",
        "RMI Number": 1007983
    },
    {
        "Workshop": "Joburg Auto Tech Cc",
        "RMI Number": 1003946
    },
    {
        "Workshop": "Johnnys Mobile Unit",
        "RMI Number": 1004541
    },
    {
        "Workshop": "Jp Motors",
        "RMI Number": 1006371
    },
    {
        "Workshop": "Jr Auto Services & Repairs",
        "RMI Number": 1006668
    },
    {
        "Workshop": "Jvr Diagnostics",
        "RMI Number": 4004209
    },
    {
        "Workshop": "Kgabotse Field Repairs Cc",
        "RMI Number": 4001709
    },
    {
        "Workshop": "Kiyk",
        "RMI Number": 4005572
    },
    {
        "Workshop": "Koenic Wheel Warehouse",
        "RMI Number": 1005627
    },
    {
        "Workshop": "Koops Mrc Cc",
        "RMI Number": 4000675
    },
    {
        "Workshop": "Kwikfin Cc T\/a Kwikfix Auto Centre",
        "RMI Number": 1005277
    },
    {
        "Workshop": "Kyalami Grand Prix Auto",
        "RMI Number": 1007740
    },
    {
        "Workshop": "Kynos Special Vehicles",
        "RMI Number": 4004314
    },
    {
        "Workshop": "L2g Workshop & Panelbeating",
        "RMI Number": 1008166
    },
    {
        "Workshop": "Landy Tech Cc",
        "RMI Number": 1003864
    },
    {
        "Workshop": "Landy Zone",
        "RMI Number": 1007483
    },
    {
        "Workshop": "Leenoe Motors",
        "RMI Number": 1007642
    },
    {
        "Workshop": "Leloba Bright Trading",
        "RMI Number": 1007439
    },
    {
        "Workshop": "Lenasia Auto Care",
        "RMI Number": 1004645
    },
    {
        "Workshop": "Leons Service Centre",
        "RMI Number": 1003421
    },
    {
        "Workshop": "Lion Power Auto Repairs",
        "RMI Number": 1006296
    },
    {
        "Workshop": "Louis Voges Motors",
        "RMI Number": 1003956
    },
    {
        "Workshop": "Lp Gearbox",
        "RMI Number": 1007417
    },
    {
        "Workshop": "Lr Auto Workshop And Service Centre",
        "RMI Number": 4005466
    },
    {
        "Workshop": "Lr Xtreme",
        "RMI Number": 4003730
    },
    {
        "Workshop": "Ltu Dyno Service Centre",
        "RMI Number": 1005279
    },
    {
        "Workshop": "Lunado Group  ",
        "RMI Number": 4003327
    },
    {
        "Workshop": "M Town Customs",
        "RMI Number": 1007520
    },
    {
        "Workshop": "M.a Jaffer & Sons Cc \/e-car &t",
        "RMI Number": 4005614
    },
    {
        "Workshop": "Mabopane Auto Center",
        "RMI Number": 4005894
    },
    {
        "Workshop": "Mabula Motors Gearbox & Diff",
        "RMI Number": 1003710
    },
    {
        "Workshop": "Maccy Racing Workshop",
        "RMI Number": 4004086
    },
    {
        "Workshop": "Maestro Recon Centre",
        "RMI Number": 4005334
    },
    {
        "Workshop": "Mahindra Bela Bela  ",
        "RMI Number": 4005685
    },
    {
        "Workshop": "Mainreef",
        "RMI Number": 1005624
    },
    {
        "Workshop": "Manaka Auto Repairs",
        "RMI Number": 1005670
    },
    {
        "Workshop": "Mandy And Sons Projects",
        "RMI Number": 1007805
    },
    {
        "Workshop": "Manies Auto Services",
        "RMI Number": 1007107
    },
    {
        "Workshop": "Maqweqwe Automotive Pretoria",
        "RMI Number": 4006151
    },
    {
        "Workshop": "Martins Auto Electrical",
        "RMI Number": 1005097
    },
    {
        "Workshop": "Mashobadieta Business Enterprise",
        "RMI Number": 1004506
    },
    {
        "Workshop": "Massi Motors",
        "RMI Number": 1001181
    },
    {
        "Workshop": "Mavi Auto Group",
        "RMI Number": 4004535
    },
    {
        "Workshop": "Mayfair Gearbox Polokwane",
        "RMI Number": 4005290
    },
    {
        "Workshop": "Mbfs & Auto Mechanics And Projects",
        "RMI Number": 4003979
    },
    {
        "Workshop": "Mbhalis Auto Clinic",
        "RMI Number": 4004171
    },
    {
        "Workshop": "M-centre Cc",
        "RMI Number": 4001424
    },
    {
        "Workshop": "Mcj Service Centre",
        "RMI Number": 1004480
    },
    {
        "Workshop": "Mds Brake And Clutch",
        "RMI Number": 4004922
    },
    {
        "Workshop": "Mec Autoworld",
        "RMI Number": 1007956
    },
    {
        "Workshop": "Mechanical Masters",
        "RMI Number": 4003580
    },
    {
        "Workshop": "Mechatronic Sa, Gearbox, Clutch, Brake M",
        "RMI Number": 1007510
    },
    {
        "Workshop": "Mechspec Auto Bosch",
        "RMI Number": 1008123
    },
    {
        "Workshop": "Mectech Motors",
        "RMI Number": 1006825
    },
    {
        "Workshop": "Megazone 160 T\/a Delport Gearbox",
        "RMI Number": 1004040
    },
    {
        "Workshop": "Merc Formance",
        "RMI Number": 1007621
    },
    {
        "Workshop": "Merctec",
        "RMI Number": 1007652
    },
    {
        "Workshop": "Mesede Motors",
        "RMI Number": 1005526
    },
    {
        "Workshop": "Mgoza Nnp Trading",
        "RMI Number": 1007176
    },
    {
        "Workshop": "Midas \u2013 Wynberg",
        "RMI Number": 1004190
    },
    {
        "Workshop": "Mk Supertech",
        "RMI Number": 1006844
    },
    {
        "Workshop": "Mobilis Auto Lab T\/a Bheka Umbuso Holdin",
        "RMI Number": 1007302
    },
    {
        "Workshop": "Mokgaga Auto Repairs",
        "RMI Number": 1008102
    },
    {
        "Workshop": "Motolek \u2013 Lenasia",
        "RMI Number": 1005691
    },
    {
        "Workshop": "Motomark Service Centre",
        "RMI Number": 4004782
    },
    {
        "Workshop": "Motor Accident Group",
        "RMI Number": 1004199
    },
    {
        "Workshop": "Motor Spec Fleetline",
        "RMI Number": 1007495
    },
    {
        "Workshop": "Motor Wize Auto Repairs",
        "RMI Number": 4006277
    },
    {
        "Workshop": "Motorsport Inc",
        "RMI Number": 1003019
    },
    {
        "Workshop": "Motoserv",
        "RMI Number": 1003917
    },
    {
        "Workshop": "Motsimani  ",
        "RMI Number": 4003578
    },
    {
        "Workshop": "Mp Tuned",
        "RMI Number": 1005631
    },
    {
        "Workshop": "Mpp Auto Mechanical",
        "RMI Number": 4003760
    },
    {
        "Workshop": "Mr Mechanic",
        "RMI Number": 1006669
    },
    {
        "Workshop": "Mte Truck & Trailer Service Centre",
        "RMI Number": 1006824
    },
    {
        "Workshop": "Mtecs Auto Centre",
        "RMI Number": 1007443
    },
    {
        "Workshop": "Mthunzi Auto And Suspension",
        "RMI Number": 4005096
    },
    {
        "Workshop": "Mycrest Trading",
        "RMI Number": 1007273
    },
    {
        "Workshop": "Nashed Tuned",
        "RMI Number": 1007817
    },
    {
        "Workshop": "Naude\u2019s Car And Truck",
        "RMI Number": 1007951
    },
    {
        "Workshop": "Nducks Maintenance And Logistics",
        "RMI Number": 1006039
    },
    {
        "Workshop": "Nelo Motor Services",
        "RMI Number": 1005620
    },
    {
        "Workshop": "New Order Investment 46  ",
        "RMI Number": 4003316
    },
    {
        "Workshop": "Nfs Tuning",
        "RMI Number": 1007912
    },
    {
        "Workshop": "Ngb Auto",
        "RMI Number": 1007264
    },
    {
        "Workshop": "Ngwazi Transport And Logistics",
        "RMI Number": 4006009
    },
    {
        "Workshop": "Nh Tech Motors Cc",
        "RMI Number": 1005232
    },
    {
        "Workshop": "Nico Britz Racing & Fleet Maintanan",
        "RMI Number": 1005567
    },
    {
        "Workshop": "Nigel Motor Services",
        "RMI Number": 1007208
    },
    {
        "Workshop": "Nix Auto Trix",
        "RMI Number": 1007353
    },
    {
        "Workshop": "Njy Motors",
        "RMI Number": 1008053
    },
    {
        "Workshop": "No Problem Auto",
        "RMI Number": 4003545
    },
    {
        "Workshop": "Northway Electrauto",
        "RMI Number": 1004315
    },
    {
        "Workshop": "Nortons Car Clinic Cc",
        "RMI Number": 1002582
    },
    {
        "Workshop": "Npc Mechanical Services",
        "RMI Number": 1007914
    },
    {
        "Workshop": "O T N Car Care",
        "RMI Number": 4004474
    },
    {
        "Workshop": "Oe Airconditioning Spares Cc",
        "RMI Number": 1001340
    },
    {
        "Workshop": "Olwagen Motors",
        "RMI Number": 4003438
    },
    {
        "Workshop": "Onfleet Mechanical",
        "RMI Number": 1008024
    },
    {
        "Workshop": "Ott Technologies  ",
        "RMI Number": 4000951
    },
    {
        "Workshop": "Overland Worx.",
        "RMI Number": 1004428
    },
    {
        "Workshop": "P & D Auto Services",
        "RMI Number": 1003287
    },
    {
        "Workshop": "P And H Merc Specialist",
        "RMI Number": 1006670
    },
    {
        "Workshop": "Papa Piet Trading And Projects  ",
        "RMI Number": 4005084
    },
    {
        "Workshop": "Parker Auto Motors",
        "RMI Number": 1007431
    },
    {
        "Workshop": "Patrix Service Centre  ",
        "RMI Number": 1005706
    },
    {
        "Workshop": "Pauls Auto Services",
        "RMI Number": 1006687
    },
    {
        "Workshop": "Pauls Mini Workshop",
        "RMI Number": 4003286
    },
    {
        "Workshop": "Pauls Workshop",
        "RMI Number": 1002919
    },
    {
        "Workshop": "Peet Jansen Service Centre",
        "RMI Number": 4000988
    },
    {
        "Workshop": "Perfect Reliable Perfomance",
        "RMI Number": 1005817
    },
    {
        "Workshop": "Performax Tuning",
        "RMI Number": 1004843
    },
    {
        "Workshop": "Performer 2000",
        "RMI Number": 1004895
    },
    {
        "Workshop": "Perkins Automotive",
        "RMI Number": 4005462
    },
    {
        "Workshop": "Peters Auto Care",
        "RMI Number": 1002476
    },
    {
        "Workshop": "Pfani And The Mechanics Workshop",
        "RMI Number": 1005604
    },
    {
        "Workshop": "Pincar Auto Services",
        "RMI Number": 1007892
    },
    {
        "Workshop": "Polo Auto Air Cc",
        "RMI Number": 1002994
    },
    {
        "Workshop": "Powermatics",
        "RMI Number": 1004906
    },
    {
        "Workshop": "Precision Auto Tuning",
        "RMI Number": 1005330
    },
    {
        "Workshop": "Pretoria East Service City Cc",
        "RMI Number": 4003543
    },
    {
        "Workshop": "Pretorius And Sons  ",
        "RMI Number": 4005896
    },
    {
        "Workshop": "Primo Executive Cars",
        "RMI Number": 1006442
    },
    {
        "Workshop": "Pro Merc Auto",
        "RMI Number": 4005354
    },
    {
        "Workshop": "Pro Nordic Motors Cc",
        "RMI Number": 1004680
    },
    {
        "Workshop": "Pro-master Mechanical",
        "RMI Number": 4004331
    },
    {
        "Workshop": "Propshaft City",
        "RMI Number": 1001448
    },
    {
        "Workshop": "Propshaft People",
        "RMI Number": 4002825
    },
    {
        "Workshop": "Protec Auto Benoni Bosch",
        "RMI Number": 1005994
    },
    {
        "Workshop": "Protec Auto Kempton Park",
        "RMI Number": 1003083
    },
    {
        "Workshop": "Quick Lane Silverton",
        "RMI Number": 4004372
    },
    {
        "Workshop": "R & D Trekker & Motordiens",
        "RMI Number": 1007723
    },
    {
        "Workshop": "R2 Workshops  ",
        "RMI Number": 1006148
    },
    {
        "Workshop": "R55 Service Centre",
        "RMI Number": 4006227
    },
    {
        "Workshop": "Raza Auto",
        "RMI Number": 1006745
    },
    {
        "Workshop": "Rc Auto Repairs",
        "RMI Number": 1003435
    },
    {
        "Workshop": "Retrohaus",
        "RMI Number": 4005645
    },
    {
        "Workshop": "Rex Diff And Gearbox Pretoria",
        "RMI Number": 4004775
    },
    {
        "Workshop": "Rhr Service Centre",
        "RMI Number": 1000237
    },
    {
        "Workshop": "Richard Service Centre",
        "RMI Number": 1006264
    },
    {
        "Workshop": "Ridgewood Auto Service",
        "RMI Number": 4002713
    },
    {
        "Workshop": "Rileys Service And Repair Centre",
        "RMI Number": 1006606
    },
    {
        "Workshop": "Rilo Auto Electrical Cc",
        "RMI Number": 1003196
    },
    {
        "Workshop": "Riverside Auto City-rac",
        "RMI Number": 1007877
    },
    {
        "Workshop": "Rjs Auto Performance",
        "RMI Number": 1007261
    },
    {
        "Workshop": "Rl Service Centre",
        "RMI Number": 4004002
    },
    {
        "Workshop": "Roadranger Transport",
        "RMI Number": 1007280
    },
    {
        "Workshop": "Rodac Diesel Injection ",
        "RMI Number": 1001529
    },
    {
        "Workshop": "Roelf Kruger Auto Repairs Motorherstelwe",
        "RMI Number": 4001546
    },
    {
        "Workshop": "Roelfs Auto Electrical",
        "RMI Number": 4004401
    },
    {
        "Workshop": "Rogens Auto Centre  ",
        "RMI Number": 4001104
    },
    {
        "Workshop": "Rolans Fitment & Motor Repair",
        "RMI Number": 1004477
    },
    {
        "Workshop": "Ronnies Tyres",
        "RMI Number": 1004564
    },
    {
        "Workshop": "Ross Duncan Auto Services Cc",
        "RMI Number": 1005835
    },
    {
        "Workshop": "Route 24 Service Centre",
        "RMI Number": 1007210
    },
    {
        "Workshop": "Ryans Auto Worx",
        "RMI Number": 4005468
    },
    {
        "Workshop": "S A Reserve Bank Head Office",
        "RMI Number": 4004272
    },
    {
        "Workshop": "Sac Diesel Kyalami Cc",
        "RMI Number": 4003380
    },
    {
        "Workshop": "Sac East Rand",
        "RMI Number": 1006705
    },
    {
        "Workshop": "Safe Time Auto Electrical",
        "RMI Number": 1004432
    },
    {
        "Workshop": "Safety Brake & Clutch \u2013 Edenv",
        "RMI Number": 1003885
    },
    {
        "Workshop": "Sams Auto Services",
        "RMI Number": 1004723
    },
    {
        "Workshop": "Savoy Car Aircon & Sound Centre",
        "RMI Number": 1006381
    },
    {
        "Workshop": "Scooby Worx",
        "RMI Number": 1005747
    },
    {
        "Workshop": "Scw Auto Electrical",
        "RMI Number": 4006066
    },
    {
        "Workshop": "Sd Group",
        "RMI Number": 1007013
    },
    {
        "Workshop": "Sebenza Rtc",
        "RMI Number": 1002457
    },
    {
        "Workshop": "Seelo Performance",
        "RMI Number": 1008162
    },
    {
        "Workshop": "Sehlabaka Engineering",
        "RMI Number": 1007761
    },
    {
        "Workshop": "Select Auto Group",
        "RMI Number": 4005456
    },
    {
        "Workshop": "Service 2 Service",
        "RMI Number": 1008125
    },
    {
        "Workshop": "Shami Business Solutions",
        "RMI Number": 1007734
    },
    {
        "Workshop": "Siyadumisa Fleet Maintenance Cc",
        "RMI Number": 1004739
    },
    {
        "Workshop": "Siza James Auto Clinic",
        "RMI Number": 1005367
    },
    {
        "Workshop": "Smash Bakkie Service Spares Propshaft Ce",
        "RMI Number": 4004473
    },
    {
        "Workshop": "Smw Swartz Motor Worx",
        "RMI Number": 1005746
    },
    {
        "Workshop": "Solihull 4 Wd",
        "RMI Number": 1005026
    },
    {
        "Workshop": "Sondela Auto",
        "RMI Number": 1005893
    },
    {
        "Workshop": "Sp Carbs Edms Bpk",
        "RMI Number": 1001561
    },
    {
        "Workshop": "Speed Auto Cc",
        "RMI Number": 1004442
    },
    {
        "Workshop": "Stallone Auto Electrical",
        "RMI Number": 1001685
    },
    {
        "Workshop": "Stargen Auto Electrical Cc",
        "RMI Number": 4001205
    },
    {
        "Workshop": "Steering & Mechanical Wizard",
        "RMI Number": 4005037
    },
    {
        "Workshop": "Stp Mechanics",
        "RMI Number": 4006026
    },
    {
        "Workshop": "Stuart Coal",
        "RMI Number": 1008163
    },
    {
        "Workshop": "Supafix Auto",
        "RMI Number": 1006925
    },
    {
        "Workshop": "Super Tune",
        "RMI Number": 1002322
    },
    {
        "Workshop": "Superior Car Clinic",
        "RMI Number": 1004946
    },
    {
        "Workshop": "Supertech Auto",
        "RMI Number": 4004227
    },
    {
        "Workshop": "Supertek Auto Works",
        "RMI Number": 1005733
    },
    {
        "Workshop": "Supreme Auto Works",
        "RMI Number": 1006465
    },
    {
        "Workshop": "Svds Motors",
        "RMI Number": 4003821
    },
    {
        "Workshop": "Swedish Auto Cc",
        "RMI Number": 1003550
    },
    {
        "Workshop": "T\/a Bb Menlyn Ford",
        "RMI Number": 4000842
    },
    {
        "Workshop": "Talladega Auto Clinic",
        "RMI Number": 4003001
    },
    {
        "Workshop": "Td & Hb Auto Repairs",
        "RMI Number": 4004277
    },
    {
        "Workshop": "Techno Spec",
        "RMI Number": 1004081
    },
    {
        "Workshop": "Technocar Auto Services-e-car",
        "RMI Number": 1003198
    },
    {
        "Workshop": "Tens Pretoria",
        "RMI Number": 4005389
    },
    {
        "Workshop": "Thato Truck Clinic",
        "RMI Number": 1006797
    },
    {
        "Workshop": "The Beamer Boyz Motor Mechanics",
        "RMI Number": 1007862
    },
    {
        "Workshop": "The Big Friendly Giant",
        "RMI Number": 4005050
    },
    {
        "Workshop": "The Bm Guy",
        "RMI Number": 1007610
    },
    {
        "Workshop": "The Garage",
        "RMI Number": 1006866
    },
    {
        "Workshop": "The Journeymann Motor \u2013 Benoni",
        "RMI Number": 1003334
    },
    {
        "Workshop": "The Kings Auto",
        "RMI Number": 1007414
    },
    {
        "Workshop": "Theonick Engineering",
        "RMI Number": 1007432
    },
    {
        "Workshop": "Thes Service Centre",
        "RMI Number": 1005396
    },
    {
        "Workshop": "Tlalas Auto Repair",
        "RMI Number": 1006890
    },
    {
        "Workshop": "Tman Workshop Mechanics",
        "RMI Number": 4006275
    },
    {
        "Workshop": "Tmk Auto",
        "RMI Number": 4005601
    },
    {
        "Workshop": "Top Speed Motors",
        "RMI Number": 1006297
    },
    {
        "Workshop": "Top Torque Auto Clinic",
        "RMI Number": 1005633
    },
    {
        "Workshop": "Toropa Trading And Enterprise",
        "RMI Number": 1006291
    },
    {
        "Workshop": "Transport Motor Repairs",
        "RMI Number": 4004971
    },
    {
        "Workshop": "Transpower Motors",
        "RMI Number": 1006067
    },
    {
        "Workshop": "Trip 3 Automotive",
        "RMI Number": 1007925
    },
    {
        "Workshop": "Truckpart",
        "RMI Number": 4002322
    },
    {
        "Workshop": "Tshirivha Auto Mechanic",
        "RMI Number": 1007778
    },
    {
        "Workshop": "Tshwane Auto Services",
        "RMI Number": 4002321
    },
    {
        "Workshop": "Ttp Auto Solution",
        "RMI Number": 4006286
    },
    {
        "Workshop": "Tvr Auto Electrical Services Cc",
        "RMI Number": 1004340
    },
    {
        "Workshop": "Twin Brother Enterprise & Propshaft",
        "RMI Number": 4003826
    },
    {
        "Workshop": "Ukulungiswa Group",
        "RMI Number": 1008027
    },
    {
        "Workshop": "Ultimate Auto",
        "RMI Number": 1006759
    },
    {
        "Workshop": "Ultimate Performance",
        "RMI Number": 1005661
    },
    {
        "Workshop": "Uminathi Auto Services",
        "RMI Number": 1007312
    },
    {
        "Workshop": "Unique Motorsport  ",
        "RMI Number": 4004286
    },
    {
        "Workshop": "Uns Trading Projects",
        "RMI Number": 1007649
    },
    {
        "Workshop": "Urbn Auto Pretoria",
        "RMI Number": 4006166
    },
    {
        "Workshop": "Uws Projects",
        "RMI Number": 1006793
    },
    {
        "Workshop": "V & S Services",
        "RMI Number": 4004934
    },
    {
        "Workshop": "V2k Service & Repair Centre",
        "RMI Number": 1006357
    },
    {
        "Workshop": "Va Auto Repairs",
        "RMI Number": 1003854
    },
    {
        "Workshop": "Vag Automotive Centre Centurion",
        "RMI Number": 4003931
    },
    {
        "Workshop": "Vag Motor Sport",
        "RMI Number": 1006952
    },
    {
        "Workshop": "Vagtec Auto Centre",
        "RMI Number": 1006891
    },
    {
        "Workshop": "Vanos Tech",
        "RMI Number": 1007090
    },
    {
        "Workshop": "Vee Motors \u2013 Workshop",
        "RMI Number": 1003992
    },
    {
        "Workshop": "Venter Gearboxes & Differentials",
        "RMI Number": 4004990
    },
    {
        "Workshop": "Vereeniging Auto Electronics",
        "RMI Number": 1007736
    },
    {
        "Workshop": "Versloot Auto Dynamics",
        "RMI Number": 4003630
    },
    {
        "Workshop": "Vib Auto Electrical Bosch",
        "RMI Number": 1005049
    },
    {
        "Workshop": "Vj Auto Clinic",
        "RMI Number": 1006105
    },
    {
        "Workshop": "Vlg Fleet Maintenance",
        "RMI Number": 1007343
    },
    {
        "Workshop": "Vom S Engineering",
        "RMI Number": 1001896
    },
    {
        "Workshop": "Vorsprung Mechanical And Auto Electrical",
        "RMI Number": 1006213
    },
    {
        "Workshop": "V-tek Service Centre & Auto Ice",
        "RMI Number": 4002226
    },
    {
        "Workshop": "Vuthani Motors",
        "RMI Number": 1007669
    },
    {
        "Workshop": "Wakko Performance",
        "RMI Number": 4005810
    },
    {
        "Workshop": "We Buy Cars",
        "RMI Number": 4006135
    },
    {
        "Workshop": "West Rand Diesel&auto Electrical Se",
        "RMI Number": 1001962
    },
    {
        "Workshop": "Wilber Van Dyk Motors",
        "RMI Number": 1006885
    },
    {
        "Workshop": "William Nicol Motors",
        "RMI Number": 1003400
    },
    {
        "Workshop": "Wilropark Motor Repairs Centre Cc",
        "RMI Number": 1003856
    },
    {
        "Workshop": "Wonderboom Car Service",
        "RMI Number": 4003488
    },
    {
        "Workshop": "Yellow Metal Auto Care",
        "RMI Number": 1008158
    },
    {
        "Workshop": "Zee Tech Auto",
        "RMI Number": 1007977
    },
    {
        "Workshop": "Zibondiwe Mechanical",
        "RMI Number": 1007149
    },
    {
        "Workshop": "Zilek Auto",
        "RMI Number": 1007490
    },
    {
        "Workshop": "Zone Offroad Products Jhb",
        "RMI Number": 1005744
    },
    {
        "Workshop": "Zoran Auto",
        "RMI Number": 1006748
    }
]
